import { useEffect, useState } from "react";
import { isAndroidApp } from "../util";
export function useGetInOnGooglePlay() {
  var _useState = useState(false),
      showGetInOnGooglePlay = _useState[0],
      setShowGetInOnGooglePlay = _useState[1];

  useEffect(function () {
    setShowGetInOnGooglePlay(!isAndroidApp());
  }, []);
  return showGetInOnGooglePlay;
}