import React from "react";
import styles from "./Button.module.css";

export interface ButtonPops {
  label: string;
  onClick: VoidFunction;
  disabled?: boolean;
  type?: "button" | "submit";
  testId?: string;
  loading?: boolean;
  className?: string;
}

export default function Button({
  label,
  onClick,
  disabled = false,
  type = "button",
  testId,
  loading,
  className,
  ...rest
}: ButtonPops) {
  return (
    <button
      className={[styles.btn, disabled && styles.disabled, className].join(" ")}
      onClick={onClick}
      disabled={disabled}
      type={type}
      data-cy={`btn-${testId || label}`}
      {...rest}
    >
      {loading ? (
        <div className={styles.spinner}>
          <div className={styles.rect1}></div>
          <div className={styles.rect2}></div>
          <div className={styles.rect3}></div>
          <div className={styles.rect4}></div>
          <div className={styles.rect5}></div>
        </div>
      ) : (
        label
      )}
    </button>
  );
}
export interface AButtonPops {
  label: string;
  disabled?: boolean;
  href: string;
  testId?: string;
  stretch?: boolean;
  loading?: boolean;
  outlined?: boolean;
  simpleOutlined?: boolean;
  rel?: string;
  externalSide?: boolean;
  onClick?: VoidFunction;
  ref?: React.LegacyRef<HTMLAnchorElement>;
}

export function AButton({
  label,
  disabled = false,
  href,
  testId,
  stretch = false,
  outlined = false,
  simpleOutlined = false,
  loading,
  rel = "",
  externalSide = false,
  onClick,
  ref,
}: AButtonPops) {
  return (
    <a
      ref={(ref) => {
        if (ref) {
          const fn = (evt: MouseEvent): void => {
            // evt.preventDefault();
            evt.stopPropagation();
            onClick();
          };
          ref.onclick = fn;
        }
      }}
      className={[
        styles.btn,
        disabled && styles.disabled,
        stretch && styles.stretch,
        outlined && styles.outlined,
        simpleOutlined && styles.simpleOutlined,
      ].join(" ")}
      href={href}
      data-cy={`a-btn-${testId}`}
      data-text={label}
      rel={rel}
      target={externalSide ? "_blank" : ""}
      {...(onClick && { onClick })}
    >
      {loading ? (
        <div className={styles.spinner}>
          <div className={styles.rect1}></div>
          <div className={styles.rect2}></div>
          <div className={styles.rect3}></div>
          <div className={styles.rect4}></div>
          <div className={styles.rect5}></div>
        </div>
      ) : (
        label
      )}
    </a>
  );
}
