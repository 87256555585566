import React, { useEffect, useState } from "react";
import { useGetInOnGooglePlay } from "../../../hooks/useGetInOnGooglePlay";
import { isAndroidApp } from "../../../util";
import { Urls } from "../../../util/constant";
import "./GetInOnGooglePlayBtn.module.css";

export interface GetInOnGooglePlayBtnPops {
    size?: number;
}

export default function GetInOnGooglePlayBtn({
    size = 54,
}: GetInOnGooglePlayBtnPops) {
    const showGetInOnGooglePlay = useGetInOnGooglePlay();

    return (
        <a
            href={Urls.GOOGLE_PLAY_URL}
            style={{
                visibility: showGetInOnGooglePlay ? "visible" : "hidden",
            }}
        >
            <img
                src="/assets/image/png/google_play_badge.png"
                height={size}
                alt="Google Play Download Link"
            />
        </a>
    );
}

export function GetInOnGooglePlaySection() {
    const showGetInOnGooglePlay = useGetInOnGooglePlay();

    return showGetInOnGooglePlay ? (
        <div style={{ textAlign: "center", marginBottom: "12px" }}>
            <p>
                Available on <b>Android</b>
            </p>
            <GetInOnGooglePlayBtn />
        </div>
    ) : (
        <></>
    );
}

export function GetInOnGooglePlayDownloadAppSection() {
    const showGetInOnGooglePlay = useGetInOnGooglePlay();

    return showGetInOnGooglePlay ? (
        <div style={{ textAlign: "center", margin: "10px 0 30px" }}>
            <h1 style={{ margin: 0 }}>Download App</h1>
            <h4>Install the app and keep searching!!</h4>
            <GetInOnGooglePlayBtn />
        </div>
    ) : (
        <></>
    );
}
